@use '../assets/css/_colors' as *;
@use '../assets/css/layout' as *;
@import '../assets/css/_media-queries';

.ConsigliPage {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;

  .Consigli {
    flex-wrap: wrap;
    padding: $space-largest 0;
    margin-top: $space-largest;

    .Consiglio {
      padding: $space-medium 0;
    }
  }
}

@include tablet-portrait {
  .ConsigliPage {
    .Consigli {
      margin-top: 0;
      padding: $space-large 0;
    }
  }
}