@use '../assets/css/layout' as *;
@use '../assets/css/colors' as *;
@use '../assets/css/typography' as *;


.BlocchiEditoriali {
  display: flex;
}

.BloccoEditoriale {

  .markdown a {
    text-decoration: underline;
  }

  .text-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;


    .text {

      height: 100%;

      .titolo {
        margin-bottom: $space-small;
      }

      .testo {
        margin-bottom: $space-small;
      }

      .occhiello { 
        margin-bottom: $space-smallest;

        h6 {
          text-transform: uppercase;
        }
      }
    }
  }
}

.BloccoEditoriale {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.titolo h3 {
  margin: 0;
}

// Mobile
@include mobile {
  .BlocchiEditoriali {
    flex-direction: column;
  }

  .BloccoEditoriale {
    height: auto;
    
    .text {
      padding: 0;

      p {
        /* @TODEL
        font-size: $font-size-smaller; = 16px
        line-height: $font-size-smaller; = 16px
        */ 
        margin: $space-small 0;
      }
    }
  }

  .BlocchiEditoriali.trio {
    flex-direction: row;
    flex-wrap: wrap; /* Allow children to wrap onto a new row */
  }
  
  .BlocchiEditoriali.trio > * {
    flex-basis: 50%; /* Set the basis for the first two children to take half the width */
  }
  
  .BlocchiEditoriali.child:nth-child(3) {
    flex-basis: 100%; /* Set the basis for the third child to take the full width */
  }
}

@include mobile-landscape {
  .BlocchiEditoriali {
    flex-direction: column;

    /* This is to avoid overlaps in rows */
    .primary-font.font-size-big {
      font-size: 38px;
      line-height: 38px;
    }
  }

  .BloccoEditoriale {
    height: auto;
    
    .text {
      padding: 0;

      p {
        /* @TODEL
        font-size: $font-size-smaller; = 16px
        line-height: $font-size-smaller; = 16px
        */ 
      }
    }
  }

  .BlocchiEditoriali.trio {
    flex-direction: row;
    flex-wrap: wrap; /* Allow children to wrap onto a new row */
  }
  
  .BlocchiEditoriali.trio > * {
    flex-basis: 50%; /* Set the basis for the first two children to take half the width */
  }
  
  .BlocchiEditoriali.child:nth-child(3) {
    flex-basis: 100%; /* Set the basis for the third child to take the full width */
  }
}

@include tablet-portrait {
  .BlocchiEditoriali {

    /* This is to avoid overlaps in rows */
    .primary-font.font-size-big {
      font-size: 30px;
      line-height: 30px;
    }

    .secondary-font.font-size-big {
      font-size: 12px;
      line-height: 12px;
    }
  }
}