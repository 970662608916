@use '../assets/css/_colors' as *;
@use '../assets/css/layout' as *;
@import '../assets/css/_media-queries';

// Catch classes with the pattern "eventi/{number}"
div[class^="consigli/"] {
  .scrolling .logo-img {
    filter: none;
  }
  .scrolling .cta-text {
    color: $primary-color;
  }
}

div[class^="consigli/"] .navigation-container {
  .cta-logo {
    transform: translateX(-100%);
    margin: 0 auto;
  }
}

.ConsiglioPage {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;

  .ConsiglioContainer .padding {
    padding: $space-large $space-largest;

    @include tablet-landscape {
      padding: $padding-value
    }
  }

  .ConsiglioContainer {
    box-sizing: border-box;
    border: 1px solid $dark-accent-color;

    .first {
      margin-top: $space-large;
    }

    @include tablet-portrait {
      flex-direction: column;  
    }
  }

  .Gallery-container {
    margin-bottom: $space-largest;
  }

  .Date, .Author {
    font-size: 16px;
  }
}

.Consiglio.sticky {
  position: sticky;
  top: 0;
  padding: $space-large 0;
  margin-top: $space-large;

  .Date {
    border-color: $dark-accent-color;
    background-color: $secondary-color;
    color: $dark-accent-color;
  }

  img {
    max-height: 50vh;
    max-width: 25vw;

    @include tablet-portrait {
      position: initial;
      max-width: 80vw;
    }
  }
}

@include tablet-landscape {
  .ConsiglioPage {
    .ConsiglioContainer {
      .first {
        margin-top: $space-largest;
        padding-top: $space-medium;
      }
    }
  }
}


@include tablet-portrait {
  .ConsiglioPage {
    .ConsiglioContainer {
      .first {
        margin-top: $space-medium;
      }
      .sticky-container {
        z-index: 2;
      }
    }
  }

  div[class^="consigli/"]  .navigation-container {
    .cta-logo {    
      margin: 0 $space-small;
      transform: translateX(0);
    }

    .logo-img {
      filter: brightness(0) invert(1);
    }
    .cta-text {
      color: $secondary-color;
    }
  }
}