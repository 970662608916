@use '../assets/css/layout' as *;

.scroll-parent {
  display: flex;
  // width: fit-content;
  overflow-x: auto;
  height: 8vh;
  min-height: 60px; 
  margin-bottom: 0 !important;
  border-top: 1px solid #909090;
  border-bottom: 1px solid #909090;

  z-index: 2;
}

p {
  margin: 0;
}

.scroll-element {
  display: flex;
  white-space: nowrap;
  font-style: italic;
  align-items: center;
}

.scroll-element span {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
