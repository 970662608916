@use '../assets/css/colors' as *;
@use '../assets/css/layout' as *;
@import '../assets/css/_media-queries';

@keyframes leftWiggle {
  0% {
    transform: translate(0%, -50%);
  }
  50% {
    transform: translate(-25%, -50%);
  }
  100% {
    transform: translate(0, -50%);
  }
}

@keyframes rightWiggle {
  0% {
    transform: translate(0%, -50%);
  }
  50% {
    transform: translate(25%, -50%);
  }
  100% {
    transform: translate(0, -50%);
  }
}

.gallery {
  width: 100%;
  padding: $space-largest 0;
  position: relative;

  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 999;
    opacity: 1;
    transition: all 0.2s;
    user-select: none;

    &:hover {
      opacity: 0.5;
      animation: orizontalWiggle 1s;
    }

    &:active {
      opacity: 1;
      color: $dark-accent-color;
    }

    &.left {
      left: $space-small;

      &:hover {
        animation: leftWiggle 1s;
      }
    }

    &.right {
      right: $space-small;

      &:hover {
        animation: rightWiggle 1s;
      }
    }
  }

  .gallery-scroll {
    display: flex;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS devices */
  
    .gallery-item {
      position: relative;
      flex: 0 0 auto;
      padding: $space-medium;
      box-sizing: border-box;
  
      img {
        max-width: 100%;
        max-height: 70vh;
        min-height: 40vh;
        display: block;
      }
  
      .caption {
        text-align: center;
      }
    }
  }
}


@include laptop {
  .gallery {
    padding: $space-large 0;
  }
}

@include tablet-portrait {
  .gallery {
    padding: $space-medium 0;
    .gallery-scroll {
      .gallery-item {
        img {
          max-height: 50vh;
          min-height: 30vh;
        }
      }
    }
  }
}

@include mobile {
  .gallery {
    padding: $space-small 0;

    .arrow {
      display: none;
    }
    .gallery-scroll {
      .gallery-item {
        padding: $space-small;

        img {
          max-height: 75vh;
          min-height: 50vh;
          max-width: 60vw;
        }
      }
    }
  }
}