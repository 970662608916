@use '../assets/css/_colors' as *;
@use '../assets/css/layout' as *;
@use '../assets/css/typography' as *;

.Consiglio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .Date {
    border: 2px solid $dark-accent-color;
    border-radius: 20px;
    padding: 2px 20px;
    background-color: $light-accent-color;
    color: $dark-accent-color;
  }

  .Autore {
    text-transform: uppercase;
  }

  img {
    margin-top: $space-small;
    // margin-bottom: $space-medium;
    max-width: 25vw;
    box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
  }

  .Title {
    margin-top: $space-small;
    margin-bottom: $space-small;
  }
}

// Mobile
@include mobile {
  .Consiglio {
    .Date {
      font-size: $font-size-smaller;
      line-height: $font-size-smaller;
    }

    .Location {
      font-size: $font-size-smallest;
      line-height: $font-size-smallest;
    }
    
    img {
      margin-top: $space-small;
      margin-bottom: 0;
    }

    .Title {
      margin-bottom: $space-small;
    }
  }
}

// Tablet
@include tablet-landscape {
  .Consiglio {
    .Date {
      font-size: $font-size-smaller;
      line-height: $font-size-smaller;
    }

    .Location {
      font-size: $font-size-smaller;
      line-height: $font-size-smaller;
    }
    
    img {
      margin-top: $space-small;
      margin-bottom:0;
      max-width: 80vw;
    }

    .Title {
      margin-bottom: $space-small;
    }
  }
}
