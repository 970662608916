@use '../assets/css/layout' as *;
@use '../assets/css/typography' as *;

.Evento {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  > * {
    width: 90%;
  }

  .Date {
    font-weight: bold;
  }

  .Location, .Date {
    text-transform: uppercase;
  }

  img {
    margin-top: $space-medium;
    // margin-bottom: $space-medium;
    max-width: 25vw;
  }

  .Title {
    margin-top: $space-small;
    margin-bottom: $space-small;
  }
}

// Tablet
@include tablet-landscape {
  .Evento {
    .Date {
      font-size: 18px;
      line-height: 20px;
    }

    .Location {
      font-size: 18px;
      line-height: 20px;
    }
    
    img {
      margin-top: $space-small;
      margin-bottom: 0;
    }

    .Title {
      margin-bottom: $space-small;
    }
  }
}

// Mobile
@include mobile {
  .Evento {
    .Date {
      font-size: 15px;
      line-height: 20px;
    }

    .Location {
      font-size:15px;
      line-height: 25px;
    }
    
    img {
      margin-top: $space-small;
      margin-bottom: 0;
    }

    .Title {
      margin-bottom: $space-small;
    }
  }
}
