$space-largest: 150px;
$space-large: 100px;
$space-medium: 50px;
$space-small: 25px;
$space-smallest: 10px;

$padding-value-biggest: 75px 75px;
$padding-value-big: 50px 50px;
$padding-value: 25px 25px;
$padding-value-small: 12px 12px;
$half-padding-value-biggest: 37.5px 37.5px;
$half-padding-value-big: 25px 25px;
$half-padding-value: 12.5px 12.5px;
$half-padding-value-small: 6px 6px;


%padding {
  padding: $padding-value-big;
}

%padding-medium {
  padding: $padding-value;
}

%half-padding {
  padding: $half-padding-value-big;
}

%half-padding-medium {
  padding: $half-padding-value;
}

%margin-h {
  margin-left: $space-medium;
  margin-right: $space-medium;
}

%margin-h-small {
  margin-left: $space-small;
  margin-right: $space-small;
}

@media screen and (max-width: 1200px) {
  %padding {
    padding: $padding-value;
  }
}

@media screen and (max-width: 480px) {
  %padding {
    padding: $padding-value-small;
  }
}