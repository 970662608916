@use '../assets/css/typography' as *;
@use '../assets/css/layout';

.Header {
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: stretch;

  > * {
    display: flex;
    align-items: flex-end
  }

  .text {
    @extend %padding;

    .title {
      width: 90%;
      font-weight: 500;
    }
  }
}

.logo-img {
  height: 80px;
}

// Laptop
@include tablet-landscape {
  .Header {
    .text {
      .title {
        width: 90%;
        /* @TODEL
        font-size: $font-size-big;
        line-height: $font-size-big;
        */ 
      }
    }
  }
}


// Tablet
@include tablet-portrait {
  .Header {
    height: 92vh; // 100vh - height of the scrolling payoff
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .text {
      .title {
        width: 90%;
        /* @TODEL
        font-size: $font-size-big;
        line-height: $font-size-big;
        */ 
      }
    }
  }
  .Header > *:first-child {
    width: 100%;
    height: 60%;
  }
}



// Mobile
@include mobile {
  .Header {
    height: 92vh; // 100vh - height of the scrolling payoff
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .text {
      .title {
        width: 90%;
      }
    }
  }
  .Header > * {
    width: 100%;
    height: 50%;
  }

  .logo-img {
    height: 65px;
  }
}