@use '../assets/css/layout' as *;
@use '../assets/css/typography' as *;

.Eventi {
  position: relative;
  background-color: black;
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: $space-medium 0 $space-largest 0;
  width: 100%;

  img {
    max-width: 30vw;
    max-height: 70vh;
  }

  .Evento {
    z-index: 2;
  }

  .cta-container {
    width: 100%;
    text-align: center;
    padding-top: $space-large;
    z-index: 2;
  }
}

// Mobile
@include mobile {
  .Eventi {
    flex-direction: column;
    padding: 0 0 $space-medium 0;

    // sticky fixed text in center
    .fixed-text {
      top: $space-large;
    }

    .Evento {
      padding: $space-medium 0;

      * {
        text-align: center;
      }
    }
  }
}

@include tablet-portrait {
  .Eventi {
    flex-direction: column;
    padding: 0 0 $space-medium 0;

    // sticky fixed text in center
    .fixed-text {
      top: $space-large;
    }

    .Evento {
      padding: $space-medium $space-small;

      * {
        text-align: center;
      }
    }

    .cta-container {
      padding-top: 0;
    }
    
    
    img {
      max-width: 80vw;
      max-height: 70vh;
    }
  }
}


