@use '../assets/css/colors' as *;
@use '../assets/css/layout' as *;
@use '../assets/css/typography' as *;

// should feel like a button
.Pagination {
  width: 100%;
  text-align: center;
  user-select: none;
  text-transform: uppercase;

  * {
    padding: $space-small;
  }

  .Next,
  .Previous {
    cursor: pointer;
  }

  .Next:hover,
  .Previous:hover,
  .LoadMore:hover {
    opacity: 0.5;
  }
}

.Pagination.LoadMore {
  cursor: pointer;
}

@include tablet-portrait {
  .Pagination.hidden {
    display: initial;
  }

  .Pagination.WithPages {
    display: none;
  }
}