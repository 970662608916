
@use '../assets/css/typography' as *;
@use '../assets/css/colors' as *;

.Partigiano {
  position: relative;
  box-sizing: border-box;
  // border: 1px solid $dark-accent-color;
}

.Partigiano .text-container .text .titolo {
  font-size: 55px;
  font-weight: 400;
  line-height: 55px;
}

.Partigiano img {
  filter: brightness(0.93);
}

.partigiano-content {
  position: relative;
  height: 100%;
}

.partigiano-content.hidden {
  visibility: hidden;
}

.Partigiano .circle-blur {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  padding-bottom: 40%;
  border-radius: 50%;
  backdrop-filter: blur(10px); /* Blurring effect */
  z-index: 1; /* Adjust the z-index as needed */
  pointer-events: none; /* Allow interaction with the elements underneath */
}


.Partigiano .scritta {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  width: 50%;
  z-index: 1;
  cursor: pointer;
  animation: rotate 20s ease-in-out infinite;
}

.Partigiano .scritta:hover {
  animation-play-state: paused;
  opacity: 0.8;
}


/* Define the rotation animation */
@keyframes rotate {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.partigiano-overlay {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  box-sizing: border-box;
}

.partigiano-overlay .overlay-content {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: end;
}

.partigiano-overlay .BloccoEditoriale {
  border: none;
}

// Laptop
@include only-laptop {
  .Partigiano {
    .text-container .text .titolo {
      font-size: 45px;
      line-height: 45px;
    }
  
    .text-container .text .testo {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

// Tablet
@include only-tablet-landscape {
  .Partigiano {
    .close-icon {
      width: 40px;
      height: 40px;
    }

    .circle-blur {
      width: 40%;
      padding-bottom: 40%;
    }
  
    .text-container .text .titolo {
      font-size: 35px;
      line-height: 35px;
    }
  
    .text-container .text .testo {
      font-size: 18px;
      line-height: 20px;
    }
  }
}

// Tablet
@include only-tablet-portrait {
  .Partigiano {
    .close-icon {
      width: 40px;
      height: 40px;
    }

    .circle-blur {
      width: 40%;
      padding-bottom: 40%;
    }
  
    .text-container .text .titolo {
      font-size: 25px;
      line-height: 25px;
    }
  
    .text-container .text .testo {
      font-size: 12px;
      line-height: 14px;
    }
  }
}

// Mobile
@include only-mobile {
  .Partigiano {
    max-height: 80vh;
    z-index: 2;

    .hidden {
      visibility: visible;
      display: block;
    }

    .circle-blur {
      width: 40%;
      padding-bottom: 40%;
    }
  
    .text-container .text .titolo {
      font-size: 30px;
      line-height: 35px;
    }
  
    .text-container .text .testo {
      font-size: 15px;
      line-height: 20px;
    }
  }
}