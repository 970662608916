@use '../assets/css/_colors' as *;
@use '../assets/css/layout' as *;
@import '../assets/css/_media-queries';

.chisiamo {
  .cta-logo {
    margin: 0 auto;
    opacity: 1;
  }

  .scrolling .cta-logo {
    opacity: 0;
  }
}

.ChiSiamoPage {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;

  .Team .BloccoEditoriale, .ConsiglioContainer .BloccoEditoriale {
    padding: $space-large $space-medium;
  }

  .Team {
    display: flex;
    flex-direction: column;

    .OneMilton {
      display: flex;
      flex-direction: row;

      &:first-child .Description {
        padding-top: $space-largest;
      }

      .ScrollingPhoto {
        .Photo {
          position: sticky;
          top: 0;
          height: 100vh;
        }
      }

      .Description {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .PhotoDescriptions {
          width: 100%;
          display: flex;
          padding-bottom: $space-medium;

          img {
            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
          }
        }

        .PhotoDescriptionsGallery {
          display: none;

          img {
            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
          }
        }
        
        .PhotoDescription {
          flex: 1;
          padding: $space-smallest;
          margin: 0;
          transform-origin: bottom center;

          &:nth-child(1) {
            transform: rotate(-15deg) translateX($space-largest);
            transform-origin: bottom right;
          }

          &:nth-child(2) {
            transform: rotate(0);
          }

          &:nth-child(3) {
            transform: rotate(15deg) translateX(-$space-largest);
            transform-origin: bottom left;
          }
        }
      }
    }
  }

  .Consiglio.sticky {
    a {
      width: 100%;
      max-height: 75vh;
      width: calc(100% - 2 * $space-medium);

      img {
        height: 100%;
        max-height: 100vh;
        width: 100%;
      }
    }
  }

  .ConsiglioContainer {
    .BloccoEditoriale.no-gallery1 {
      padding-bottom: 0;
    }
  
    .BloccoEditoriale.no-gallery2 {
      padding-top: 0;
    }
  }
  
}


@include tablet-portrait {
  .chisiamo {
    .cta-logo {
      transform: translateX(0);
      margin: 0 $space-small;
    }
  }

  .ChiSiamoPage {
    .Team {
      .OneMilton {
        flex-direction: column;
        margin-bottom: $space-medium;

        &:first-child .Description {
          padding-top: 0;
        }
  
        .ScrollingPhoto {
          .Photo {
            position: relative;
            top: auto;
            height: 50vh;

            img {
              object-position: center;
            }
          }
        }
  
        .Description {
          .PhotoDescriptions {
            display: none;
          }
          .PhotoDescriptionsGallery {
            display: initial;
            padding-top: 0;
          }
        }
      }
    }

    .ConsiglioContainer {
      flex-direction: column;
    }
  }
}

@include mobile {
  .chisiamo {
    .cta-logo {
      transform: translateX(0);
      margin: 0 $space-small;
    }
  }

  .ChiSiamoPage {
    .Team .BloccoEditoriale, .ConsiglioContainer .BloccoEditoriale {
      padding: $space-medium $space-small $space-smallest $space-small;
    }

    .Team {
      .OneMilton {
        .ScrollingPhoto {
          .Photo {
            img {
              object-position: center;
            }
          }
        }
      }
    }
  }
}