@use '../assets/css/typography' as *;
@use '../assets/css/layout' as *;

.Promo {
  display: flex;

  .BloccoEditoriale {
    border: none;
    padding: 30px 30px;
  }

  .occhiello *{
    /* @TODEL
    font-size: $font-size-smaller;
    */
    font-weight: bold;
  }

  .titolo {
    /* @TODEL
    font-size: $font-size-normal;
    */
    font-weight: normal;
  }

  img {
    max-height: 100vh;
  }
}

.Promo > :first-child {
  justify-content: center;
}

.Promo > :last-child {
  flex-direction: column;
}

.promo-title,
.promo-item-text {
  text-transform: uppercase
}

/* @TBD move to main stylesheet ?? */
.separator { flex: 1 }

@include laptop {
  .Promo {
    img {
      // max-height: 80vh;
    }
  }
}


@include tablet-landscape {
  .Promo {
    flex-direction: column;

    img {
      // height: 50vh;
    }

    .BloccoEditoriale {
      padding: $space-medium $space-small;
    }
  }
}


// Mobile
@include mobile {
  .Promo {
    flex-direction: column;

    img {
      height: 50vh;
    }

    .BloccoEditoriale {
      padding: $space-small $space-smallest;
    }
  }
}