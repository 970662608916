$mobile-max: 480px;
$mobile-landscape-max: 768px;
$tablet-portrait-max: 1024px;
$tablet-landscape-max: 1200px;
$laptop-max: 1440px;
$desktop-max: 1600px;

// Media query mixins with max-width
@mixin mobile {
  @media screen and (max-width: #{$mobile-max}) {
    @content;
  }
}

@mixin mobile-landscape {
  @media screen and (max-width: #{$mobile-landscape-max}) {
    @content;
  }
}

@mixin tablet-portrait {
  @media screen and (max-width: #{$tablet-portrait-max}) {
    @content;
  }
}

@mixin tablet-landscape {
  @media screen and (max-width: #{$tablet-landscape-max}) {
    @content;
  }
}

@mixin laptop {
  @media screen and (max-width: #{$laptop-max}) {
    @content;
  }
}

@mixin desktop {
  @media screen and (max-width: #{$desktop-max}) {
    @content;
  }
}

// Media query mixins with min-width (added "only" prefix)
@mixin only-mobile {
  @media screen and (max-width: #{$mobile-max}) {
    @content;
  }
}

@mixin only-mobile-landscape {
  @media screen and (min-width: #{$mobile-max}) and (max-width: #{$mobile-landscape-max}) {
    @content;
  }
}

@mixin only-tablet-portrait {
  @media screen and (min-width: #{$mobile-landscape-max}) and (max-width: #{$tablet-portrait-max}) {
    @content;
  }
}

@mixin only-tablet-landscape {
  @media screen and (min-width: #{$tablet-portrait-max}) and (max-width: #{$tablet-landscape-max}) {
    @content;
  }
}

@mixin only-laptop {
  @media screen and (min-width: #{$tablet-landscape-max}) and (max-width: #{$laptop-max}) {
    @content;
  }
}

@mixin only-desktop {
  @media screen and (min-width: #{$tablet-landscape-max}) and (max-width: #{$desktop-max}) {
    @content;
  }
}

@mixin only-large-desktop {
  @media screen and (min-width: #{$desktop-max + 1}) {
    @content;
  }
}
