@use '../assets/css/typography' as *;
@use '../assets/css/layout' as *;
@use '../assets/css/colors' as *;

/**
* The logo in home should be translated by its width on the right
*/
.home .cta-logo {
  transform: translateX(100%);

  @include tablet-landscape {
    transform: translateX(0);
  }
}

.scrolling .cta-logo {
  opacity: 0;
}

/**
* The divs inside the home should align in rows as they can fit
*/
.Home {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;

  .Header {
    height: 92vh; /*NOTE: 100vh - height of scrolling payoff */
  }

  .BlocchiEditoriali.trio {
    border-top: 1px solid $dark-accent-color;
  }

  .BlocchiEditoriali .quadrablock {
    @include mobile {
      border-bottom: 1px solid $dark-accent-color;
    }
  }

  .BloccoEditoriale {
    box-sizing: border-box;
    // border: 1px solid $dark-accent-color;

    .testo {
      text-transform: uppercase;
    }
  }

  .Eventi {
    .bg-text {
      opacity: 0.5;
    }
  }
} 

/**
* graphics
*/



/* Specials */
.Home .Evento .Description, .Home .Evento .Gallery {
  display: none;
}

/* @TODO hard-coded because of date */
.Home .square .BloccoEditoriale h3 {
  /* @TODEL
  font-size: $font-size-big;
  letter-spacing: -1.4px;
  line-height: $font-size-big;
  */ 

  font-weight: 500;
  max-width: 80%;
}

.icon-cat-static {
  &:hover {
    opacity: 0%;
  }
}