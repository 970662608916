@use '../assets/css/_colors' as *;
@use '../assets/css/layout' as *;
@import '../assets/css/_media-queries';

// Catch classes with the pattern "eventi/{number}"
div[class^="eventi/"] {
  .scrolling .logo-img {
    filter: none;
  }
  .scrolling .cta-text {
    color: $primary-color;
  }
}

div[class^="eventi/"] .navigation-container {
  .cta-logo {
    transform: translateX(-100%);
    margin: 0 auto;
  }
}

.EventoPage {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;

  .EventoContainer .padding {
    padding: $space-large $space-largest;

    @include tablet-landscape {
      padding: $padding-value
    }
  }

  .EventoContainer {
    box-sizing: border-box;
    border: 1px solid $dark-accent-color;

    .first {
      margin-top: $space-large;
    }

    .Date {
      font-size: 21px;
      line-height: 44px;

      @include tablet-landscape {
          font-size: 18px;
          line-height: 20px;
      }

      @include tablet-portrait {
          font-size: 20px;
          line-height: 25px;
      }

      @include mobile-landscape {
          font-size: 15px;
          line-height: 20px;
      }
    }

    .Location {
      font-size: 16px;
    }

    @include tablet-portrait {
      flex-direction: column;
    }
  }

  .Evento.sticky {
    position: sticky;
    top: 0;
    padding: $space-large 0;
    margin-top: $space-large;

    img {
      max-height: 50vh;

      @include tablet-portrait {
        position: initial;
        max-width: 80vw;
      }
    }
  }

  .Gallery-container {
    margin: $space-medium 0;
  }
}

@include tablet-landscape {
  .EventoPage {
    .EventoContainer {
      .first {
        margin-top: $space-largest;
        padding-top: $space-medium;
      }
    }
  }
}


@include tablet-portrait {
  .EventoPage {
    .EventoContainer {
      .first {
        margin-top: $space-medium;
      }
      .sticky-container {
        z-index: 2;
      }
    }
  }

  div[class^="eventi/"] .navigation-container {
    .cta-logo {    
      margin: 0 $space-small;
      transform: translateX(0);
    }

    .logo-img {
      filter: brightness(0) invert(1);
    }
    .cta-text {
      color: $secondary-color;
    }
  }
}