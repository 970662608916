@use '../assets/css/typography' as *;
@use '../assets/css/layout' as *;

.Banner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .close-icon {
    margin: $space-medium;
    z-index: 3;
  }
}

.Banner .banner-content {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
}

.Banner .image-container {
  flex: 1.5;
}

.Banner .BloccoEditoriale {
  flex: 1;
}

.Banner .BloccoEditoriale {

  .cta-container {
    text-align: start;
    font-weight: normal;
  }
  
  .text {

    width: 80%;

    * {
      text-align: start;
      font-weight: normal;
    }

    .occhiello * {
      font-weight: bold;
    }
  }
}

.Banner .banner-bg.bg-accent {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 70%;
}

// Mobile
@include mobile {
  .Banner .banner-content {
    flex-direction: column;

    .BloccoEditoriale {
      .text {
        width: 100%;
      }
    }

    .BloccoEditoriale .text p {
      font-size: 15px
    }

    .close-icon {
      margin: $space-smallest;
    }

    .relative {
      // remove relative positioning
      position: static;
    }
  }
}

// Tablet
@include tablet-portrait {
  .Banner .banner-content {
    flex-direction: column;

    .BloccoEditoriale {
      .text {
        width: 100%;
      }
    }

    .close-icon {
      margin: $space-smallest;
    }

    .relative {
      // remove relative positioning
      position: static;
    }
  }
}

@include laptop {
  .Banner .banner-content {
    width: 90%;
  }
}