@use '../assets/css/typography' as *;
@use '../assets/css/layout' as *;


.Policies {
  padding-top: $space-large;
}

@include tablet-landscape {
  .Policies {
    padding-top: $space-largest;
  }
}