@use '../assets/css/_colors' as *;
@use '../assets/css/layout' as *;
@import '../assets/css/_media-queries';

.eventi {
  .logo-img {
    filter: brightness(0) invert(1);
  }
  .scrolling .logo-img {
    filter: none;
  }
  .cta-text {
    color: $secondary-color;
  }
  .scrolling .cta-text {
    color: $primary-color;
  }
}

.EventiPage {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;

  .Eventi {
    flex-wrap: wrap;
    padding: $space-largest 0;
    margin-top: $space-largest;

    .Evento {
      min-width: 34%;
      padding: $space-medium 0;
    }
  }

  .Eventi.EventiPassati {
    margin-top: 0;
    .BloccoEditoriale {
      width: 100%;
      text-align: center;
    }

    .Evento {
      min-width: 0;
    }
  }
}

@include tablet-portrait {
  .EventiPage {
    .Eventi {
      margin-top: 0;
      padding: $space-large 0;
    }
  }
}