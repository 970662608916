@use '../assets/css/colors' as *;
@use '../assets/css/layout' as *;
@use '../assets/css/typography' as *;

.navigation-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: transparent; /* Adjust the background color as needed */
  z-index: 999; /* Adjust the z-index as needed */
  padding-top: $space-small;
  padding-bottom: $space-small;
  transition: background-color 0.3s ease;
}

.cta-logo {
  display: inline-block;
  margin: 0 auto;
  z-index: 9999;
  cursor: pointer;

  a {
    display: block;
  }
  transition: opacity 0.3s ease, margin 0.3s ease;
}

.cta-menu {
  // position it on the right
  position: absolute;
  right: $space-medium;

  
  display: inline-block;
  z-index: 9999;
  cursor: pointer;

  .menu-container {
    position: relative;
    display: inline-block;

    .cta-text {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.overlay.open {
  opacity: 1;
  visibility: visible;
}

.menu {
  position: fixed;
  top: 0;
  right: -33%;
  width: 33%;
  height: 100vh;
  z-index: 9999;
  transition: right 0.3s;
}

.menu.open {
  right: 0;
}

.menu-toggle {
  position: absolute;
  top: 30px;
  right: 30px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.Navigation {
  padding: 0 $space-medium;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}

.Navigation ul {
  list-style-type: none;
  padding: 0;
}

.Navigation li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.Navigation a {
  @extend %primary-font;
  position: relative; /* Needed for absolute positioning of the pseudo-element */
  display: flex; /* Ensure the anchor behaves like a flex container */
  align-items: center; /* Center the content vertically */
}

/*
.Navigation a:hover {
  color: #ff8c00;
}
*/
.Navigation {

  .contatti {
    width: fit-content;
  }

  // last child li
  .social {
    margin-top: $space-small;
    text-transform: uppercase;

    a {  
      display: inline-block;
      margin-right: $space-small;
        
      &:hover {
        opacity: 0.8;
      }
    }
  }

  .hover-icon {

    position: relative; /* Needed for absolute positioning of the pseudo-element */
    display: flex; /* Ensure the anchor behaves like a flex container */
    align-items: center; /* Center the content vertically */

    &::after{
      content: ""; /* Empty content for pseudo-element */
      background: url('../assets/icons/occhioSingolo.svg') no-repeat;
      background-size: 35px; /* Set the desired size of the background image */
      width: 35px; /* Adjust width as needed */
      height: 18px; /* Adjust height as needed */
      margin-left: 18px; /* Adjust the margin as needed */
      transform: translateY(50%); /* Center the pseudo-element vertically */
      opacity: 0;
    }
    
    &.active::after {
      opacity: 1;
    }

    &:hover,&:hover::after {
      opacity: 0.8;
    }
  }
}

.cta-text, .menu-toggle {
  font-weight: bold;
  letter-spacing: 3px;
  text-transform: uppercase;
  cursor: pointer;
}
@include tablet-landscape {
  .menu {
    right: -50%;
    width: 50%;
  }
  
  .cta-menu {
    right: $space-small;
  }

  .cta-logo {
    margin: 0 $space-small;
    transform: translateX(0);
  }
}

@include tablet-portrait {
  .menu {
    right: -50%;
    width: 50%;
  }
  .navigation-container {
    padding-top: $space-smallest;
    padding-bottom: $space-smallest;
  }
  .cta-menu {
    right: $space-small;
  }

  .cta-logo {
    margin: 0 $space-small;
    transform: translateX(0);
  }
}

@include mobile {
  .menu {
    right: -100%;
    width: 100%;
  }
}
