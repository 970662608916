@use 'colors' as *;
@use 'layout' as *;
@use 'typography';
 
 /* Hide scrollbar for Chrome, Safari and Opera */
 *::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
} 

/* body {
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
} */

img {
  object-fit: cover;
}

.image {
  max-width: 25vw;
  max-height: 40vh;
  min-width: 200px;
  min-height: 400px;
}

.icon {
  @extend %margin-h;
  width: 45px;
  height: 45px;
}

/* Classes */
.relative {
  position: relative;
}

.hidden {
  display: none;
}

.m-h-1 {
  margin-left: 30px;
  margin-right: 30px;
}

.h-100 {
  height: 100%;
}

.w-25 {
  width: 25%;
}

.w-33 {
  width: 33%;
}

.w-50 {
  width: 50%;
}

.w-66 {
  width: 66%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.d-f {
  display: flex;
}

.f-col {
  flex-direction: column;
}

.f-1 {
  flex: 1;
  box-sizing: border-box;
}

.f-2 {
  flex: 2;
}

.f-wrap {
  flex-wrap: wrap;
}

.width-1-2 {
  flex-basis: 50%;
}

.width-1-3 {
  flex-basis: 33%;
}

.w-h-60 {
  width: 60% !important;
  height: 60% !important;
}

.full {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
}

.full-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 2;
}

.top-left,
.top-right,
.top-center {
  position: absolute;
  top: 0;
}

.top-left {
  left: 0;
}

.top-right {
  right: 0;
}

.top-center {
  left: 50%;
  transform: translateX(-50%);
}

.centered {
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%,-50%) !important;
}

.square {
  position: relative;
}

.square::before {
  content: "";
  display: block;
  padding-bottom: 100%; /* Set padding-bottom to 100% of the width */
}

/* Additional styles for the content inside the square */
.square > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.cta-container {

  a.accent::after {
    content: ' >>';
    display: inline;
    transition: all 0.3s;
  }
  
  a.accent:hover {
    opacity: 0.8;
  }
  
  a.accent:hover::after {
    animation: ctaCycle 1s infinite;
  }
}

@keyframes ctaCycle {
  0% {
    content: ' >>';
  }
  25% {
    content: ' >>>';
  }
  50% {
    content: '  >>';
  }
  75% {
    content: '   >';
  }
  100% {
    content: ' >';
  }
}

.cta-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  /*
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  */ 
  /* Additional styling properties */
}

.fixed-text {
  position: sticky;
  top: 0;
  width: 100%;
  text-align: center;
  z-index: 1;
}