@use '../assets/css/typography' as *;
@use '../assets/css/layout' as *;

.Footer {
  z-index: 2;

  .BloccoEditoriale {
    border: none;
  }

  .FooterContacts {
    p {
      margin: 0;
      font-style: italic;
    }

    p.no-cap { text-transform: lowercase; }
  }

  .row, .policies {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;

    div {
      flex: 1;
    }
  }

  /* @TODEL
  .middle p {
    font-size: $font-size-extra-smaller;
  }
  */ 

  .cta-container {
    font-size: $font-size-extra-smaller;
  }

  .row.first div p {
    font-style: italic;
    text-transform: capitalize;
  }

  .policies{
    padding-top: 0;
    padding-bottom: 0;

    >*:first-child {
      padding-left: 0;
    }

    a {
      // flex: 0 1 auto;
      // line-height: 0;
      text-transform: uppercase;
      // margin: 0;
    }
  } 
}

// Mobile
@include mobile {
  .Footer {
    .row {
      flex-direction: column;
    }

    .row:first-child div {
      margin-bottom: 1.5rem;
    }

    .policies {
      padding: 0;
      justify-content: center;

      a {
        padding-right: 0;
      }
    }
  }
}

@include mobile-landscape {
  .Footer {
    .row {
      flex-direction: column;
    }

    .row:first-child div {
      margin-bottom: 1.5rem;
    }
  }
}
