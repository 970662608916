@import "_media-queries";

/* 
    @TODO move variables to dedicated file

    Es:
    $primary-color: #007bff;

    // Define responsive color adjustments
    $primary-color-responsive: (
        default: $primary-color,
        small-screen: darken($primary-color, 10%),
        extra-small-screen: lighten($primary-color, 20%)
    );

    .body {
      color: map-get($primary-color-responsive, default);
    }
*/

$font-size-biggest: 250px;
$font-size-bigger: 100px;
$font-size-big: 70px;
$font-size-bigging: 50px;
$font-size-normal: 50px;
$font-size-small: 40px;
$font-size-smaller: 24px;
$font-size-extra-smaller: 20px;
$font-size-smallest: 18px;

%primary-font {
    font-family: 'Piazzolla', serif !important;
}

.primary-font {
    @extend %primary-font;

    &.font-size-biggest {
        font-size: 250px;
        line-height: 250px;
        letter-spacing: -5px;

        @include tablet-portrait {
            font-size: 200px;
            line-height: 200px;
        }

        // Media query mixins
        @include mobile {
            font-size: 110px;
            line-height: 110px;
        }
    }

    &.font-size-bigger {
        font-size: $font-size-bigger;
        letter-spacing: -2px;
        line-height: 100px;

        font-weight: 500;

        @include laptop {
            font-size: 80px;
            line-height: 80px;
        }

        @include tablet-portrait {
            font-size: 45px;
            line-height: 45px;
        }
    }

    &.font-size-big {

        font-size: $font-size-big;
        letter-spacing: -1.4px;
        line-height: 70px;

        @include laptop {
            font-size: 55px;
            line-height: 55px;
        }

        @include tablet-landscape {
            font-size: 50px;
            line-height: 50px;
        }

        @include tablet-portrait {
            font-size: 45px;
            line-height: 45px;
        }

        @include mobile-landscape {
            font-size: 38px;
            line-height: 38px;
        }
    }

    &.font-size-normal {
        font-size: $font-size-normal;
        letter-spacing: -1px;
        line-height: $font-size-normal;

        @include tablet-landscape {
            font-size: 40px;
            line-height: 40px;
        }

        @include tablet-portrait {
            font-size: 35px;
            line-height: 35px;
        }

        @include mobile-landscape {
            font-size: 30px;
            line-height: 30px;
        }
    }

    &.font-size-small {
        font-size: $font-size-small;
        line-height: 56px; 

        @include laptop {
            font-size: 30px;
            line-height: 35px;
        }

        @include tablet-landscape {
            font-size: 25px;
            line-height: 30px;
        }

        @include mobile {
            font-size: 20px;
            line-height: 25px; 
        }
    }

    &.font-size-smaller {
        font-size: $font-size-smaller;
        line-height: 70px;
    }

    &.font-size-extra-smaller {
        font-size: $font-size-extra-smaller;
        letter-spacing: -0.4px;
        line-height: 70px; 
    }

    &.font-size-smallest {
        font-size: $font-size-smallest;
        line-height: 27px;
    }
}

%secondary-font {
    font-family: 'Space Mono', monospace !important;
    letter-spacing: 0;
    font-size: 20px;
}

.secondary-font {
    @extend %secondary-font; 

    margin-top: 0;
    margin-bottom: 0;

    line-height: 27px;

    @include tablet-landscape {
        font-size: 16px;
        line-height: 20px;
    }

    /* Default alias @TODO: change references to secondary-font with secondary-font.font-size-normal */
    &.font-size-normal {
        font-size: 18px;

        @include tablet-landscape {
            font-size: 18px;
        }
    }

    &.font-size-big, .font-size-big {
        font-size: 22px;
        line-height: 42px;

        @include tablet-landscape {
            font-size: 18px;
            line-height: 20px;
        }

        @include tablet-portrait {
            font-size: 20px;
            line-height: 25px;
        }

        @include mobile-landscape {
            font-size: 15px;
            line-height: 20px;
        }
    }

    &.font-size-small, .font-size-small {
        font-size: 14px;

        @include mobile-landscape {
            font-size: 12px;
        }
    }
}