@use '../assets/css/layout' as *;
@use '../assets/css/typography' as *;

.Consigli {
  position: relative;
  background-color: white;
  color: black;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: $space-medium 0 $space-largest 0;
  width: 100%;

  img {
    max-width: 30vw;
    max-height: 70vh;
  }

  .Consiglio {
    z-index: 2;
  }

  .cta-container {
    width: 100%;
    text-align: center;
    padding-top: $space-large;
    z-index: 2;
  }
}

// Mobile
@include mobile {
  .Consigli {
    flex-direction: column;
    padding: 0 0 $space-medium 0;

    img {
      width: calc(100vw - 2 * $space-small);
      max-width: none;
    }

    // sticky fixed text in center
    .fixed-text {
      top: $space-large;
    }

    .Consiglio {
      padding: $space-medium 0;

      * {
        text-align: center;
      }
    }
  }
}

@include tablet-portrait {
  .Consigli {
    flex-direction: column;
    padding: 0 0 $space-medium 0;

    img {
      max-width: 70vw;
    }


    // sticky fixed text in center
    .fixed-text {
      top: $space-large;
    }

    .Consiglio {
      padding: $space-medium $space-small;

      * {
        text-align: center;
      }
    }

    .cta-container {
      padding-top: 0;
    }
  }
}
