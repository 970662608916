@use 'colors' as *;
@use 'layout' as *;
@use 'typography' as *;

.white { color: $light-accent-color; }
.light { color: $primary-color; }
.dark { color: $secondary-color; }

.disabled { 
    opacity: 0.5;
    pointer-events: none; 
}

.accent { color: $accent-color; }
a.accent, span.accent {
    text-transform: uppercase;
    font-weight: bold;
}
a.accent::after { 
    content: ' >>'; 
}

.bg-light { 
    color: $secondary-color;
    background-color: $primary-color;    
}

.bg-dark { 
    color: $primary-color;
    background-color: $secondary-color;    
}

.bg-accent {
    color: $secondary-color;
    background-color: $accent-color;
}

.bg-accent-light {
    color: $primary-color;
    background-color: $accent-color;
}

.bg-text {
    @extend %primary-font;

    color: $dark-accent-color;
    font-style: italic;
}

.bg-light .bg-text {
    color: $light-accent-color;
}

hr { padding: 0; margin: 0; }

// typography
body {

    h1 {
        margin: 0;
    }

    h2, .h2 {
        margin: 0;
    }

    h3, .h3 {
        margin: 0;
    }

    h6.secondary-font, .h6.secondary-font {
        text-transform: uppercase;
        margin: 0;
    }

    a {
        @extend %secondary-font;

        color: inherit;
        text-decoration: none;
    }

    .secondary-font-p { @extend %secondary-font; }
}

// layout
.padding {
    @extend %padding;
}
.padding-small {
    @extend %padding-medium;
}
.half-padding {
    @extend %half-padding;
}

// assets
.close-icon {
    width: 55px;
    height: 55px;
    position: relative;
    cursor: pointer;
  
  .line {
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: $primary-color;
    transform-origin: center;
    top: 50%;
    left: 0;
    right: 0;
    margin-top: -1px;
  }
  
  .line:first-child {
    transform: rotate(45deg);
  }
  
  .line:last-child {
    transform: rotate(-45deg);
  }
}


.lines {
    position: relative;
  }
  
  .lines::before,
  .lines::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: #848484;
    z-index: 1;
  }
  
  .lines::before {
    left: calc(33.33% - 0.5px); /* Adjust positioning to align with the edge */
  }
  
  .lines::after {
    left: calc(66.66% - 0.5px); /* Adjust positioning to align with the edge */
  }
  .lines.lines-black::before,
  .lines.lines-black::after {
    background-color: $dark-accent-color;
  }
  
  .lines.lines-white::before,
  .lines.lines-white::after {
    background-color: #848484;
  }

.Aforisma {
    // italic
    font-style: italic;
    z-index: 2;
}


// Mobile
@include mobile-landscape {
    .lines::before,
    .lines::after {
        display: none;
    }

    .lines::before,
    .lines::after {
        display: initial;
        left: calc(50% - 1px);
    }
}


@include laptop {
    .lines::before,
    .lines::after {
        width: 1px;
    }
}